import { useState, useCallback } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import styled from '@emotion/styled';
import img from '../../img/loading_bg.jpeg';

export const UnityCanva = () => {
  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener
  } = useUnityContext({
    loaderUrl: 'unity-build/app.loader.js',
    dataUrl: 'unity-build/app.data',
    frameworkUrl: 'unity-build/app.framework.js',
    codeUrl: 'unity-build/app.wasm'
  });

  window.addEventListener('talking', () => {
    sendMessage("BrowserEvent", "Talking",1);
  });

  window.addEventListener('stop-talking', () => {
    sendMessage("BrowserEvent", "Talking",0);
  });

  const loadingPercentage = Math.round(loadingProgression * 100);
  return (
    <>
      {!isLoaded && (
        // We'll conditionally render the loading overlay if the Unity
        // Application is not loaded.
        <div
          className="loading-overlay"
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            zIndex: 10,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '40px',
            fontWeight: '900',
            textShadow: '1px 1px 10px rgb(0 0 0 / 30%)',
            textTransform: 'uppercase',
            background: `center/cover no-repeat url(${img})`
          }}>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              top: '60%'
            }}>
            <p>Loading... ({loadingPercentage}%)</p>
          </div>{' '}
        </div>
      )}
      {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <StyledUnity className="unity" unityProvider={unityProvider}>
          {/* {
              !room &&
              <App />
            } */}
        </StyledUnity>
      }
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-use-before-define
const StyledUnity = styled(Unity)`
  &&& {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
  }
`;
export default UnityCanva;
